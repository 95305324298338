
import { Vue } from "vue-property-decorator";

import headerView from "./page/Header.vue";
import footerView from "./page/Footer.vue";

/* tslint:disable:no-console */
declare let apigClientFactory: any;

export default Vue.extend({
  name: "otpTimeIsOver",
  data: () => {
    return {
      title: ""
    };
  },
  components: {
    headerView,
    footerView
  },
  methods: {
    // featchValidity() {
    //     const params = {
    //         // This is where any header, path, or querystring request params go.
    //     };
    //     const body = {
    //         // This is where you define the body of the request
    //         encode: this.$store.getters.getEncode()
    //     };
    //     const additionalParams = {};
    //     const apigClient = apigClientFactory.newClient();
    //     apigClient.apiV1IdentityValidatePost(params, body, additionalParams)
    //         .then((response: any) => {
    //             console.log("response", response)
    //         }).catch((error: any) => {
    //             console.log("error", error)
    //             if (error.data.statusCode === 400) {
    //                 this.$router.push(`/${this.$store.getters.getEncode()}`);
    //             } else if (error.data.statusCode === 401) {
    //                 this.$router.push(`/${this.$store.getters.getEncode()}`);
    //             } else {
    //                 this.$router.push(`/${this.$store.getters.getEncode()}`);
    //             }
    //         })
    // },
  },

  mounted() {
    if (this.$store.getters.getTitle() === "F") {
      this.title = "Ibu";
    } else if (this.$store.getters.getTitle() === "M") {
      this.title = "Bapak";
    }
    this.$store.commit("SET_ENCODE", "");
    this.$store.commit("SET_REQUEST_ID", "");
    this.$store.commit("SET_OWNER_HP", "");
    this.$store.commit("SET_POLICY_OWNER", "");
    this.$store.commit("SET_POLICY_INSURED", "");
    this.$store.commit("SET_POLICY_NUMBER", "");
    this.$store.commit("SET_TRANSACTION_TYPE", "");
    this.$store.commit("SET_CODE_01", "");
    this.$store.commit("SET_CODE_02", "");
    this.$store.commit("SET_CODE_03", "");
    this.$store.commit("SET_DOB", "");
    this.$store.commit("SET_LOGGED_IN", false);
    this.$store.commit("SET_IS_LOGIN", false);
  }
});
