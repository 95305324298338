
import { Vue } from "vue-property-decorator";

import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import axios from 'axios'

/* tslint:disable:no-console */
declare let apigClientFactory: any;

export default Vue.extend({
  name: "login",

  data: () => {
    return {
      encode: "",
      date: "",
      submitted: false,
      isLoading: false,
      idGroupFund: '',
      message: ""
    };
  },

  validations: {
    date: {
      required
    }
  },

  methods: {
    showModal() {
      this.$root.$emit("bv::show::modal", "modal-1", "#btnShow");
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-1", "#btnShow");
    },

    handleSubmit(e: any) {
      this.submitted = true;

      //stop here if from is invalid

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.isLoading = true;
      // console.log('encode', this.$store.getters.getEncode());
      // console.log('date', this.date);
      let dateFormat = this.$moment(this.date).format("DDMMYYYY");
      // console.log('date format', dateFormat);
      const params = {
        // This is where any header, path, or querystring request params go.
      };
      const body = {
        // This is where you define the body of the request
        encode: this.$store.getters.getEncode(),
        dob: dateFormat
      };
      const additionalParams = {};
      const apigClient = apigClientFactory.newClient();
      apigClient
        .apiV1IdentityUserPost(params, body, additionalParams)
        .then((response: any) => {
          // console.log('response', response.data);
          this.idGroupFund = response.data.fundGroup;
          console.log("response fundGroup", response.data.fundGroup)
          this.$store.commit("SET_OWNER_HP", response.data.ownerHp);
          this.$store.commit("SET_TITLE", response.data.title);
          this.$store.commit("SET_POLICY_OWNER", response.data.policyOwner);
          this.$store.commit("SET_POLICY_INSURED", response.data.policyInsured);
          this.$store.commit("SET_POLICY_NUMBER", response.data.policyNumber);
          this.$store.commit(
            "SET_TRANSACTION_TYPE",
            response.data.transactionType
          );
          this.$store.commit("SET_CODE_1", response.data.code1);
          this.$store.commit("SET_CODE_2", response.data.code2)
          this.$store.commit("SET_PRODUCT", response.data.product);
          this.$store.commit("SET_CURRENT_FUND_1", response.data.currentSwitchingFund1 !== undefined ? response.data.currentSwitchingFund1: "-" );
          this.$store.commit("SET_CURRENT_FUND_2", response.data.currentSwitchingFund2 !== undefined ? response.data.currentSwitchingFund2: "-" );
          this.$store.commit("SET_CURRENT_FUND_3", response.data.currentSwitchingFund3 !== undefined ? response.data.currentSwitchingFund3: "-" );
          this.$store.commit("SET_CURRENT_FUND_4", response.data.currentSwitchingFund4 !== undefined ? response.data.currentSwitchingFund4: "-" );
          this.$store.commit("SET_CURRENT_FUND_5", response.data.currentSwitchingFund5 !== undefined ? response.data.currentSwitchingFund5: "-" );
          this.$store.commit("SET_CURRENT_REDIRECTION_FUND_1", response.data.currentRedirectionFund1 !== undefined ? response.data.currentRedirectionFund1: "-" );
          this.$store.commit("SET_CURRENT_REDIRECTION_FUND_2", response.data.currentRedirectionFund2 !== undefined ? response.data.currentRedirectionFund2: "-" );
          this.$store.commit("SET_CURRENT_REDIRECTION_FUND_3", response.data.currentRedirectionFund3 !== undefined ? response.data.currentRedirectionFund3: "-" );
          this.$store.commit("SET_CURRENT_REDIRECTION_FUND_4", response.data.currentRedirectionFund4 !== undefined ? response.data.currentRedirectionFund4: "-" );
          this.$store.commit("SET_CURRENT_REDIRECTION_FUND_5", response.data.currentRedirectionFund5 !== undefined ? response.data.currentRedirectionFund5: "-" );
          this.$store.commit("SET_DOB", response.data.dob);
          this.$store.commit("SET_LOGGED_IN", true);
          // console.log('url', this.$store.getters.getOwnerHp);
          this.getGroupFund();
          
        
        })
        .catch((error: any) => {
          if (error.data.statusCode === 401) {
            this.message = `Mohon maaf ${error.data.message} tercatat sudah memberikan respon atas konfirmasi ini. Terima Kasih atas partipasi Anda`;
            this.date = "";
            this.isLoading = false;
            this.showModal();
          } else {
            this.isLoading = false;
            this.date = "";
            this.message = `Tanggal Lahir yang Dimasukkan Salah `;
            this.showModal();
          }
        });

        
    },

    getGroupFund() {
      console.log("get group Fund")
      console.log("get group fund id", this.idGroupFund)

      let id = this.idGroupFund.replace("Group Fund ", "")
      console.log("id", id)

       let encode = this.$store.getters.getEncode() != '' ? this.$store.getters.getEncode() : null;
        const uri = `https://9j38jh6wqb.execute-api.ap-southeast-1.amazonaws.com/dev/api/v1/questioner/group-fund?id=${id}`;
        // const uri = 'http://localhost:3000/api/v1/upload/file'
        let response = ''
        axios.get(uri, {
          headers: {
            'Authorization': encode
          }
        }).then((response: any) => {
           this.$store.commit("SET_PURPOSE_FUND", response.data.groupFund.content);
            this.isLoading = false;
            this.$router.push("/");

        }).catch((error) => {
            if (error.data.statusCode === 401) {
            this.message = `Mohon maaf ${error.data.message} tercatat sudah memberikan respon atas konfirmasi ini. Terima Kasih atas partipasi Anda`;
            this.date = "";
            this.isLoading = false;
            this.showModal();
          } else {
            this.isLoading = false;
            this.date = "";
            this.message = `Terjadi Kesalahan `;
            this.showModal();
          }
        })

    },

    featchValidity() {
      const params = {
        // This is where any header, path, or querystring request params go.
      };
      const body = {
        // This is where you define the body of the request
        encode: this.$store.getters.getEncode()
      };
      const additionalParams = {};
      const apigClient = apigClientFactory.newClient();
      apigClient
        .apiV1IdentityValidatePost(params, body, additionalParams)
        .then((response: any) => {
          // console.log('response', response);
        })
        .catch((error: any) => {
          // console.log('error', error);
          if (error.data.statusCode === 400) {
            this.$router.push(`/${this.$store.getters.getEncode()}`);
          } else if (error.data.statusCode === 401) {
            this.$router.push(`/${this.$store.getters.getEncode()}`);
          } else {
            this.$router.push(`/${this.$store.getters.getEncode()}`);
          }
        });
    }
  },
  mounted() {
    this.featchValidity();
    // this.date = "1990-01-01T00:00:00.000Z";
  }
});
