import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';


// import bootstrap vue
import BootstrapVue from 'bootstrap-vue';
// import font Awesome Vue
import { library } from '@fortawesome/fontawesome-svg-core';
// tslint:disable-next-line: max-line-length
import { faUser, faLock, faFileInvoice, faSignOutAlt, faHome, faUsers, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
// import vuelidate (form validation)
import Vuelidate from 'vuelidate';
// import vue moment
import moment from 'vue-moment';

// import Vue sweetalaret2
import VueSweetAlaret2 from 'vue-sweetalert2';



import './styles/index.scss';


// add Font Awesome Icon
library.add(
  faUser,
  faLock,
  faFileInvoice,
  faSignOutAlt,
  faHome,
  faUsers,
  faPlus,
  faTimes,
);
// add component font awesome icon
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('datetime', Datetime);






// add Bootstrap Vue
Vue.use(BootstrapVue);
// add Vuelidate
Vue.use(Vuelidate);

Vue.use(moment);

// add VueSweetAlaret2
Vue.use(VueSweetAlaret2);





Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
