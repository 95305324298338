import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
    key: 'sunlife-msr-app',
    storage: localStorage,
});

export default new Vuex.Store({
  plugins: [vuexPersist.plugin],

  state: {
    encode: '',
    title: '',
    ownerHp: '',
    ownerEmail: '',
    policyOwner: '',
    policyInsured: '',
    policyNumber: '',
    product: '',
    transactionType: '',
    currentFund1: '',
    currentFund2: '',
    currentFund3: '',
    currentFund4: '',
    currentFund5: '',
    currentRedirectionFund1: '',
    currentRedirectionFund2: '',
    currentRedirectionFund3: '',
    currentRedirectionFund4: '',
    currentRedirectionFund5: '',
    code1: '',
    code2: '',
    purposeFund: '',
    dob: '',
    requestId: '',
    loggedIn: false,
    isLogin: false,

  },
  mutations: {
    SET_ENCODE: (state: any, data) => {
      state.encode = data;
    },
    SET_TITLE: (state: any, data) => {
      state.title = data;
    },
    SET_OWNER_HP: (state: any, data) => {
      state.ownerHp = data;
    },
    SET_POLICY_OWNER: (state: any, data) => {
      state.policyOwner = data;
    },
    SET_POLICY_INSURED: (state: any, data) => {
      state.policyInsured = data;
    },
    SET_POLICY_NUMBER: (state: any, data) => {
      state.policyNumber = data;
    },
    SET_TRANSACTION_TYPE: (state: any, data) => {
      state.transactionType = data;
    },
    SET_PRODUCT: (state: any, data) => {
      state.product = data;
    },
    SET_CURRENT_FUND_1: (state: any, data) => {
      state.currentFund1 = data;
    },
    SET_CURRENT_FUND_2: (state: any, data) => {
      state.currentFund2 = data;
    },
    SET_CURRENT_FUND_3: (state: any, data) => {
      state.currentFund3 = data;
    },
    SET_CURRENT_FUND_4: (state: any, data) => {
      state.currentFund4 = data;
    },
    SET_CURRENT_FUND_5: (state: any, data) => {
      state.currentFund5 = data;
    },
    SET_CURRENT_REDIRECTION_FUND_1: (state: any, data) => {
      state.currentRedirectionFund1 = data;
    },
    SET_CURRENT_REDIRECTION_FUND_2: (state: any, data) => {
      state.currentRedirectionFund2 = data;
    },
    SET_CURRENT_REDIRECTION_FUND_3: (state: any, data) => {
      state.currentRedirectionFund3 = data;
    },
    SET_CURRENT_REDIRECTION_FUND_4: (state: any, data) => {
      state.currentRedirectionFund4 = data;
    },
    SET_CURRENT_REDIRECTION_FUND_5: (state: any, data) => {
      state.currentRedirectionFund5 = data;
    },
    SET_CODE_1: (state: any, data) => {
      state.code1 = data;
    },
    SET_CODE_2: (state: any, data) => {
      state.code2 = data;
    },
    SET_PURPOSE_FUND: (state: any, data) => {
      state.purposeFund = data;
    },
    SET_DOB: (state: any, data) => {
      state.dob = data;
    },
    SET_REQUEST_ID: (state: any, data) => {
      state.requestId = data;
    },
    SET_LOGGED_IN: (state: any, data) => {
      state.loggedIn = data;
    },
    SET_IS_LOGIN: (state: any, data) => {
      state.isLogin = data;
    },

  },
  getters: {
    getEncode: (state) => () => {
      return state.encode;
    },
    getTitle: (state) => () => {
      return state.title;
    },
    getOwnerHp: (state) => () => {
      return state.ownerHp;
    },
    getPolicyOwner: (state) => () => {
      return state.policyOwner;
    },
    getPolicyInsured: (state) => () => {
      return state.policyInsured;
    },
    getPolicyNumber: (state) => () => {
      return state.policyNumber;
    },
    getTransactionType: (state) => () => {
      return state.transactionType;
    },
    getProduct: (state) => () => {
      return state.product;
    },
    getCode1: (state) => () => {
      return state.code1;
    },
    getCode2: (state) => () => {
      return state.code2;
    },
    getCurrentFund1: (state) => () => {
      return state.currentFund1;
    },
    getCurrentFund2: (state) => () => {
      return state.currentFund2;
    },
    getCurrentFund3: (state) => () => {
      return state.currentFund3;
    },
    getCurrentFund4: (state) => () => {
      return state.currentFund4;
    },
    getCurrentFund5: (state) => () => {
      return state.currentFund5;
    },
    getCurrentRedirectionFund1: (state) => () => {
      return state.currentRedirectionFund1;
    },
    getCurrentRedirectionFund2: (state) => () => {
      return state.currentRedirectionFund2;
    },
    getCurrentRedirectionFund3: (state) => () => {
      return state.currentRedirectionFund3;
    },
    getCurrentRedirectionFund4: (state) => () => {
      return state.currentRedirectionFund4;
    },
    getCurrentRedirectionFund5: (state) => () => {
      return state.currentRedirectionFund5;
    },
    getPurposeFund: (state) => () => {
      return state.purposeFund;
    },
    getDob: (state) => () => {
      return state.dob;
    },
    getRequestId: (state) => () => {
      return state.requestId;
    },
    getLoggedIn: (state) => () => {
      return state.loggedIn;
    },
    getIsLogin: (state) => () => {
      return state.isLogin;
    },
  },
  actions: {

  },

});
