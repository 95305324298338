import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Check from '../views/Check.vue';
import Success from '../views/Success.vue';
import OtpNotValid from '../views/OtpNotValid.vue';
import OtpTimeIsOver from '../views/OtpTimeisOver.vue';
import Store from '../store/index';


Vue.use(VueRouter);
function AuthGuard(to: any, from: any, next: any) {
  if (Store.getters.getEncode() !== null && Store.getters.getEncode() !== ''
    && Store.getters.getIsLogin() !== false) {
    next();
    return;
  } else {
    next('*');
  }
}

function AuthGuardLogin(to: any, from: any, next: any) {
  if (Store.getters.getEncode() !== null && Store.getters.getEncode() !== '' &&
    Store.getters.getLoggedIn() !== false) {
    next();
    return;
  } else {
    next('*');
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    // beforeEnter: AuthGuardLogin,
    component: Home,
  },
  {
    path: '*',
    name: 'check',
    component: Check,


  },
  {
    path: '/info',
    name: 'otpTimeIsOver',
    beforeEnter: AuthGuardLogin,
    component: OtpTimeIsOver,

  },
  {
    path: '/error',
    name: 'otpNotValid',
    beforeEnter: AuthGuardLogin,
    component: OtpNotValid,

  },
  {
    path: '/success',
    name: 'success',
    beforeEnter: AuthGuardLogin,
    component: Success,

  },
  {
    path: '/login',
    name: 'login',
    beforeEnter: AuthGuard,
    component: Login,

  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
