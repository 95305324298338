
  import {
    required,
    requiredIf,
    email,
    minLength,
    sameAs,
  } from 'vuelidate/lib/validators';
  
  import {
    Vue
  } from 'vue-property-decorator';
  
  
  import headerView from './page/Header.vue';
  import footerView from './page/Footer.vue';
  import otp from './page/Otp.vue';
  import term from './page/Term.vue';
  import axios from 'axios'
  
  const MAX_FILE_SIZE = 3145728;
  
  const fileExtValidation = (value: File) => {
    if (!value) {
      return true;
    }
    return (
      value.type === 'image/jpeg' ||
      value.type === 'image/png' ||
      value.type === 'application/pdf'
    );
  };
  
  const sizeValidation = (value: File) => (!value ? true : value.size <= MAX_FILE_SIZE);

  const maxLength = (value: any) => (value > 3 ? false : true);
  declare let apigClientFactory: any;


  export default Vue.extend({
    name: 'home',
    components: {
      headerView,
      footerView,
      term,
      otp
    },
    data() {
      return {
        policyNumber: '',
        policyInsured: '',
        validationSize: true,
        policyOwner: 'Wahyu',
        title: 'Bapak',
        transactionType: 'Pengalihan Dana Investasi (Fund Switching)',
        product: '',
        polis: {
          purposeFund1: '',
          purposeFund2: '',
          purposeFund3: '',
          purposeFund4: '',
          pusrposeFund5: '',
          fileIdentityPemilik: '',
          sizePemilikPolis: true,
        },
        table: {
          currentFundSwitching: [
            {
              "name": "",
              "percentage": ''
            }
          ],
          currentFundRedirection: [
            {
              "name": "",
              "percentage": ''
            }
          ],
          purposeFund: [
            {
              "name": "" ,
              "amount" : 0 ,
              "percentage": '' ,
              "isDisabled": true 
            },
            {
              "name": "" ,
              "amount" : 0 ,
              "percentage": '' ,
              "isDisabled": true 
            },
            {  
              "name": "" ,
              "amount" : 0 ,
              "percentage": '' ,
              "isDisabled": true 
            },
            {
              "name": "" ,
              "amount" : 0 ,
              "percentage": '' ,
              "isDisabled": true
            },
            {
              "name": "" ,
              "amount" : 0 ,
              "percentage": '' ,
              "isDisabled": true 
            },
          ],

          purposeFundRedirection: [
            {
              "name": "" ,
              "amount" : 0 ,
              "percentage": '' ,
              "isDisabled": true 
            },
            {
              "name": "" ,
              "amount" : 0 ,
              "percentage": '' ,
              "isDisabled": true 
            },
            {
              "name": "" ,
              "amount" : 0 ,
              "percentage": '' ,
              "isDisabled": true 
            },
            {
              "name": "" ,
              "amount" : 0 ,
              "percentage": '' ,
              "isDisabled": true 
            },
            {
              "name": "" ,
              "amount" : 0 ,
              "percentage": '',
              "isDisabled": true 
            },
          ]
        },
        selected: null,
        // options: [
        //   { value: null, text: 'Please select an option' },
        //   { value: 'a', text: 'This is First option' },
        //   { value: 'b', text: 'Selected Option' },
        //   { value: { C: '3PO' }, text: 'This is an option with object value' },
        //   { value: 'd', text: 'This one is disabled', disabled: true }
        // ],
        options: [
          {value: '', text: "Pilih Dana Investasi "} , 
          {value: 'Aggressive Multi Plus - Fund', text: "Aggressive Multi Plus - Fund",},
          {value: 'SLI Aggressive- Fund', text: "SLI Aggressive- Fund", }, 
          {value: 'SLI Conservative- Fund', text: "SLI Conservative- Fund"},
          {value: 'SLI Moderate- Fund', text: "SLI Moderate- Fund"}, 
          {value: 'SLI Xtra Aggresive - Fund', text: "SLI Xtra Aggresive - Fund"},
        ],    
        successInput: false,
        submitted: false,
        isCheacked: 'false',
        isLoading: false,
        isDisabled: false,
        code01: '',
        code02: '',
        message: '',
        errorMessage: '',
        totalCurrentPersenSwitching: 0,
        totalCurrentPersenRedirection: 0,
        totalPurposePersenSwitching: 0,
        totalPurposePersenRedirection: 0,
        listFileUpload: [],
        count: 1,
        saveAmount: 0,
        code1: '',
        code2: ''
      }
  
    },
  
    validations: {
      polis: {
        fileIdentityPemilik: {
          required,
          sizeValidation,
          fileExtValidation
        },
        
      }
    },
  
    methods: {
  
      showModal() {
        this.$root.$emit('bv::show::modal', 'modal-1', '#btnShow')
      },
  
      hideModal() {
        this.$root.$emit('bv::hide::modal', 'modal-1', '#btnShow')
  
      },
  
      showModal2() {
        this.$root.$emit('bv::show::modal', 'modal-2', '#btnShow')
      },
  
      hideModal2() {
        this.$root.$emit('bv::hide::modal', 'modal-2', '#btnShow')
      },

      showModal4() {
        this.$root.$emit('bv::show::modal', 'modal-4', '#btnShow')
      },
  
      hideModal4() {
        this.$root.$emit('bv::hide::modal', 'modal-4', '#btnShow')
      },
  
      handleSubmit(e: any) {
        this.submitted = true;
        console.log("new purpose fund", this.table.purposeFund)

      
        // stop here if form is invalid
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.showModal()
          return;
        } else {
          this.isLoading = true;
          this.isDisabled = true;

          if(this.code1 === 'Y' && this.code2 === 'Y') {
            let hasDuplicateSwitching: boolean = false;
            let arraySwitching = this.table.purposeFund.filter((x: any) => { if(x.name !== '') return true})
            arraySwitching.map((v: any) => v.name as string).sort().sort((a: any, b: any) => {
              console.log("a", a)
              console.log("b", b)
              if (a === b) hasDuplicateSwitching = true
              return 0;
            }) 
            console.log('hasDuplicate', hasDuplicateSwitching)

            let hasDuplicateRedurection: boolean = false;
            let arrayRedurection = this.table.purposeFundRedirection.filter((x: any) => { if(x.name !== '') return true})
            arrayRedurection.map((v: any) => v.name as string).sort().sort((a: any, b: any) => {
              console.log("a", a)
              console.log("b", b)
              if (a === b) hasDuplicateRedurection = true
              return 0;
            }) 
            console.log('hasDuplicate', hasDuplicateRedurection)

            if(hasDuplicateSwitching || hasDuplicateRedurection) {
                this.isLoading = false;
                this.isDisabled = false;
                this.errorMessage = 'Silakan memilih dana investasi yang berbeda'
                this.showModal4()
            } else if(this.totalPurposePersenSwitching !== 100 || this.totalPurposePersenRedirection !== 100 ) {
                this.isLoading = false;
                this.isDisabled = false;
                this.errorMessage = 'Total Persentasi belum 100%'
                this.showModal4()
            } else if(this.table.purposeFund[0].name === '' && this.table.purposeFundRedirection[0].name === ''  ) {
                this.isLoading = false;
                this.isDisabled = false;
                this.errorMessage = 'Dana investasi harus dipilih  terlebih dahulu'
                this.showModal4()
            } else {
                this.submitForm();
            }
              
            } 
            else if(this.code1 === 'Y') {
            let hasDuplicateSwitching: boolean = false;
            let array = this.table.purposeFund.filter((x: any) => { if(x.name !== '') return true})
            array.map((v: any) => v.name as string).sort().sort((a: any, b: any) => {
              console.log("a", a)
              console.log("b", b)
              if (a === b) hasDuplicateSwitching = true
              return 0;
            }) 
            console.log('hasDuplicate', hasDuplicateSwitching)


            if(hasDuplicateSwitching) {
              this.isLoading = false;
              this.isDisabled = false;
              this.errorMessage = 'Silakan memilih dana investasi yang berbeda'
              this.showModal4()
            } else if(this.totalPurposePersenSwitching !== 100) {
              this.isLoading = false;
              this.isDisabled = false;
              this.errorMessage = 'Total Persentasi belum 100%'
              this.showModal4()
            } else if(this.table.purposeFund[0].name === '' ) {
              this.isLoading = false;
              this.isDisabled = false;
              this.errorMessage = 'Dana investasi harus dipilih  terlebih dahulu'
              this.showModal4()
            } else {
              this.submitForm();
            }
  

          } else if(this.code2 === 'Y') {
              let hasDuplicateRedurection: boolean = false;
              let array = this.table.purposeFundRedirection.filter((x: any) => { if(x.name !== '') return true})
              array.map((v: any) => v.name as string).sort().sort((a: any, b: any) => {
                console.log("a", a)
                console.log("b", b)
                if (a === b) hasDuplicateRedurection = true
                return 0;
              }) 
              console.log('hasDuplicate', hasDuplicateRedurection)

              if(hasDuplicateRedurection) {
                this.isLoading = false;
                this.isDisabled = false;
                this.errorMessage = 'Silakan memilih dana investasi yang berbeda'
                this.showModal4()
              } else if (this.totalPurposePersenRedirection !== 100) {
                  this.isLoading = false;
                  this.isDisabled = false;
                  this.errorMessage = 'Total Persentasi belum 100%'
                  this.showModal4()
              } else if (this.table.purposeFundRedirection[0].name === '' ) {
                  this.isLoading = false;
                  this.isDisabled = false;
                  this.errorMessage = 'Dana investasi harus dipilih  terlebih dahulu'
                  this.showModal4()
                } else {
                  this.submitForm();
                }
          }

        }

      },

      handleFileUpload() {
         this.$v.$touch();
          const fileIdentityPemilik: any = this.checkFile(this.$refs.fileIdentityPemilik) as any;
          this.polis.fileIdentityPemilik = fileIdentityPemilik === null ? undefined : fileIdentityPemilik ;
      },

      checkFile(inputFile: any)  {
        let file = null;
        if (inputFile != undefined) {
          file = inputFile.files[0];
        }
        return file;
      },


      async submitForm() {
         if (this.polis.fileIdentityPemilik !== undefined && this.polis.fileIdentityPemilik !== null && this.polis.fileIdentityPemilik !== '' ) {
          this.listFileUpload.push({
            code: 'file_Identitas_Pemilik' as never,
            x: this.uploadFile(this.polis.fileIdentityPemilik, 'file_Identitas_Pemilik') as never
          } as never);
        }
  
        if (this.listFileUpload.length > 0) {
          this.message = "Sedang Mengupload File ..."
          await Promise.all(this.listFileUpload.map((a: any) => a.x)).then((x: any) => {
            // console.log('x response', x)
            x.forEach((a: any) => {
  
              if (a.data.data.imageName === 'file_Identitas_Pemilik') {
                this.polis.fileIdentityPemilik = a.data.data.location
              }
  
            })
          
          })

  
        }
        this.message = "sedang memproses data"
        this.sendData()

      },


      sendData() {
        console.log("sedang mengirim data")
      const auth = this.$store.getters.getEncode() !== '' ? this.$store.getters.getEncode() : null
        // console.log('auth', auth)
        const params = {
          // This is where any header, path, or querystring request params go.
          authorization: auth,
        };
        let code1 = this.code1
        let code2 = this.code2

        let bodyResquest = {}
        if(this.code1 ==='Y') {
          this.table.currentFundSwitching.forEach((x: any, index) => {
            bodyResquest[`currentPercentage${index+1}`] = Number(x.percentage)
          })
          this.table.purposeFund.forEach((x: any, index) => {
            bodyResquest[`purposeFund${index+1}`] = x.name
            bodyResquest[`purposePercentage${index+1}`] = Number(x.percentage)
          })
 
        }

        if(this.code2 === 'Y') {
           this.table.currentFundRedirection.forEach((x: any, index) => {
            bodyResquest[`currentRedirectionPercentage${index+1}`] = Number(x.percentage)
          })
          this.table.purposeFundRedirection.forEach((x: any, index) => {
            bodyResquest[`purposeRedirectionFund${index+1}`] = x.name
            bodyResquest[`purposeRedirectionPercentage${index+1}`] = Number(x.percentage)
          })
          
        }
        bodyResquest['imagePendukung'] =  this.polis.fileIdentityPemilik

        console.log('body request', bodyResquest);

        const body = bodyResquest
        // const body = {
        //   // This is where you define the body of the request
        //   currentPercentage1: this.table.currentFundSwitching[0].percentage !== undefined ? this.table.currentFundSwitching[0].percentage : 0 ,
        //   currentPercentage2: this.table.currentFundSwitching[1].percentage !== undefined ? this.table.currentFundSwitching[1].percentage : 0 ,
        //   currentPercentage3: this.table.currentFundSwitching[2].percentage !== undefined ? this.table.currentFundSwitching[2].percentage : 0 ,
        //   currentPercentage4: this.table.currentFundSwitching[3].percentage !== undefined ? this.table.currentFundSwitching[3].percentage : 0 ,
        //   currentPercentage5: this.table.currentFundSwitching[4].percentage !== undefined ? this.table.currentFundSwitching[4].percentage : 0 ,
        //   currentRedirectionPercentage1: this.table.currentFundRedirection[0].percentage !== undefined ? this.table.currentFundRedirection[0].percentage : 0,
        //   currentRedirectionPercentage2: this.table.currentFundRedirection[1].percentage !== undefined ? this.table.currentFundRedirection[1].percentage : 0,
        //   currentRedirectionPercentage3: this.table.currentFundRedirection[2].percentage !== undefined ? this.table.currentFundRedirection[2].percentage : 0,
        //   currentRedirectionPercentage4: this.table.currentFundRedirection[3].percentage !== undefined ? this.table.currentFundRedirection[3].percentage : 0,
        //   currentRedirectionPercentage5: this.table.currentFundRedirection[4].percentage !== undefined ? this.table.currentFundRedirection[4].percentage : 0,
        //   purposeFund1: this.table.purposeFund[0].name !== undefined ? this.table.purposeFund[0].name : '-' ,
        //   purposeFund2: this.table.purposeFund[1].name !== undefined ? this.table.purposeFund[0].name : '-',
        //   purposeFund3: this.table.purposeFund[2].name !== undefined ? this.table.purposeFund[0].name : '-',
        //   purposeFund4 : this.table.purposeFund[3].name !== undefined ? this.table.purposeFund[0].name: '-',
        //   purposeFund5: this.table.purposeFund[4].name !== undefined ? this.table.purposeFund[0].name: '-',
        //   purposePercentage1: this.table.purposeFund[0].percentage !== undefined ? this.table.purposeFund[0].percentage : 0,
        //   purposePercentage2: this.table.purposeFund[1].percentage !== undefined ? this.table.purposeFund[1].percentage : 0,
        //   purposePercentage3: this.table.purposeFund[2].percentage !== undefined ? this.table.purposeFund[2].percentage : 0,
        //   purposePercentage4: this.table.purposeFund[3].percentage !== undefined ? this.table.purposeFund[3].percentage : 0,
        //   purposePercentage5: this.table.purposeFund[4].percentage !== undefined ? this.table.purposeFund[4].percentage : 0,
        //   purposeRedirectionFund1: this.table.purposeFundRedirection[0].name !== undefined ? this.table.purposeFundRedirection[0].name: '-',
        //   purposeRedirectionFund2: this.table.purposeFundRedirection[1].name !== undefined ? this.table.purposeFundRedirection[1].name: '-',
        //   purposeRedirectionFund3: this.table.purposeFundRedirection[2].name !== undefined ? this.table.purposeFundRedirection[2].name: '-',
        //   purposeRedirectionFund4: this.table.purposeFundRedirection[3].name !== undefined ? this.table.purposeFundRedirection[3].name: '-',
        //   purposeRedirectionFund5: this.table.purposeFundRedirection[4].name !== undefined ? this.table.purposeFundRedirection[4].name: '-',
        //   purposeRedirectionPercentage1: this.table.purposeFundRedirection[0].percentage !== undefined ? this.table.purposeFundRedirection[0].percentage: 0,
        //   purposeRedirectionPercentage2: this.table.purposeFundRedirection[1].percentage !== undefined ? this.table.purposeFundRedirection[1].percentage: 0,
        //   purposeRedirectionPercentage3: this.table.purposeFundRedirection[2].percentage !== undefined ? this.table.purposeFundRedirection[2].percentage: 0,
        //   purposeRedirectionPercentage4: this.table.purposeFundRedirection[3].percentage !== undefined ? this.table.purposeFundRedirection[3].percentage: 0,
        //   purposeRedirectionPercentage5: this.table.purposeFundRedirection[4].percentage !== undefined ? this.table.purposeFundRedirection[4].percentage: 0,

        //   imagePendukung: this.polis.fileIdentityPemilik           
        // };

        const additionalParams = {};
        const apigClient = apigClientFactory.newClient();
        // console.log('body', body);
  
        apigClient.apiV1QuestionerCreatePost(params, body, additionalParams)
          .then((response: any) => {
             this.createOtpm();
          }).catch((error: any) => {
            if(error.data.message  === "otp max created") {
               this.submitted = false;
              this.isLoading = false;
              this.errorMessage = 'Data tidak dapat diproses, silakan  klik kembali url  yang didaptkan ';
              this.showModal4();

            }
            this.submitted = false;
            this.isLoading = false;
            this.errorMessage = 'Terjadi kesalahan ';
            this.showModal4();
            // console.log('error');
          })
      },

       createOtpm() {
        const auth = this.$store.getters.getEncode() !== '' ? this.$store.getters.getEncode() : null
        const params = {
          // This is where any header, path, or querystring request params go.
          authorization: auth,
        };
        const body = {
          // This is where you define the body of the request
          phoneNumber: this.$store.getters.getOwnerHp(),
          type: 'VOI',
        };
        const additionalParams = {};
        const apigClient = apigClientFactory.newClient();
  
        apigClient.apiV1OtpCreatePost(params, body, additionalParams)
          .then((response: any) => {
            // console.log('success create otp');
            // console.log('response', response.data.message);
            // console.log('request data', response.data.message.requestId);
            this.$store.commit('SET_REQUEST_ID', response.data.message.requestId);
            this.isLoading = false;
            this.successInput = true;
          }).catch((error: any) => {
            this.submitted = false;
            this.isLoading = false;
            this.errorMessage = 'Terjadi kesalahan';
            this.showModal4();
            // console.log('error', error);
  
          })
  
      },

      uploadFile(fileToUpload: any, nameImage: string) {
        const fd = new FormData();
        const fileName = this.renameFileName(fileToUpload);
  
        fd.append('file', fileToUpload!);
        fd.append('policyNumber', this.policyNumber);
        fd.append('nameImage', nameImage);
  
        // console.log('from data', fd);
        let encode = this.$store.getters.getEncode() != '' ? this.$store.getters.getEncode() : null;
        const uri = ' https://9j38jh6wqb.execute-api.ap-southeast-1.amazonaws.com/dev/api/v1/upload/file';
        // const uri = 'http://localhost:3000/api/v1/upload/file'
        let response = ''
        return axios.post(uri, fd, {
          headers: {
            'Authorization': encode
          }
        })

  
      },

      renameFileName(fileToRename: any): string {
        let oldFileName = fileToRename;
  
        // console.log(oldFileName);
  
        let newFileName;
  
        return newFileName;
        // newFileName
      },

      numberWithCommas(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },

      checkNumberCurrentSwitching(evt, indexTable) {
          let totalNumber = 0;
          this.table.currentFundSwitching.forEach((x, index) => {
            totalNumber += +x.percentage
            console.log("total number", totalNumber)

            // if(x.percentage > 100) {
            //   evt.preventDefault()
            //   this.errorMessage = "Total persentase tidak boleh melebihi 100%"
            //   this.showModal4()
            // }

            // if(totalNumber === 100){
            //   evt.preventDefault()
            //   this.errorMessage = "Total persentase sudah 100%"
            //   this.showModal4()
            // }

            if (this.table.currentFundSwitching[indexTable].percentage.toString().length > 2) {
              evt.preventDefault()
            }
          })

          evt = (evt) ? evt : window.event;
        let charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
            evt.preventDefault();   
        } else if(charCode == 46) {
            evt.preventDefault();   
        } else {
            return true;
        }  
      },

      isNumberCurrentSwitching(evt, indexTable) {
          let totalNumber = 0;
          this.table.currentFundSwitching.forEach((x, index) => {
            totalNumber += +x.percentage
            this.totalCurrentPersenSwitching = totalNumber
            console.log("total number", totalNumber)

            // if(x.percentage > 100) {
            //   evt.preventDefault()
            //   this.errorMessage = "Total persentase tidak boleh melebihi 100%"
            //   this.showModal4()
            // }

            // if(totalNumber === 100){
            //   evt.preventDefault()
            //   this.errorMessage = "Total persentase sudah 100%"
            //   this.showModal4()
            // }

            if (this.table.currentFundSwitching[indexTable].percentage.toString().length > 2) {
              evt.preventDefault()
            }
          })

      },

      checkNumberPurposeSwitching(evt, indexTable) {
          let totalNumber = 0;
          this.table.purposeFund.forEach((x, index) => {
            totalNumber += +x.percentage
            console.log("total number", totalNumber)

            // if(x.percentage > 100) {
            //   evt.preventDefault()
            //   this.errorMessage = "Total persentase tidak boleh melebihi 100%"
            //   this.showModal4()
            // }


            // if(totalNumber === 100){
            //   evt.preventDefault()
            //   this.errorMessage = "Total persentase sudah 100%"
            //   this.showModal4()
            // }

            if (this.table.purposeFund[indexTable].percentage.toString().length > 2) {
              evt.preventDefault()
            }
          })

          evt = (evt) ? evt : window.event;
        let charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
            evt.preventDefault();   
        } else if(charCode == 46) {
            evt.preventDefault();   
        } else {
            return true;
        }  
      },


      isNumberPurposeSwitching(evt, indexTable) {
          let totalNumber = 0;
          this.table.purposeFund.forEach((x, index) => {
            totalNumber += +x.percentage
            this.totalPurposePersenSwitching = totalNumber
            console.log("total number", totalNumber)

            // if(x.percentage > 100) {
            //   evt.preventDefault()
            //   this.errorMessage = "Total persentase tidak boleh melebihi 100%"
            //   this.showModal4()
            // }

            // if(totalNumber === 100){
            //   evt.preventDefault()
            //   this.errorMessage = "Total persentase sudah 100%"
            //   this.showModal4()
            // }

            if (this.table.purposeFund[indexTable].percentage.toString().length > 2) {
              evt.preventDefault()
            }
          })

      },


      checkNumberCurrentRedirection(evt, indexTable) {
          let totalNumber = 0;
          this.table.currentFundRedirection.forEach((x, index) => {
            totalNumber += +x.percentage
            console.log("total number", totalNumber)

            // if(x.percentage > 100) {
            //   evt.preventDefault()
            //   this.errorMessage = "Total persentase tidak boleh melebihi 100%"
            //   this.showModal4()
            // }


            // if(totalNumber === 100){
            //   evt.preventDefault()
            //   this.errorMessage = "Total persentase sudah 100%"
            //   this.showModal4()
            // }

            // if (this.table.currentFundRedirection[indexTable].percentage.toString().length > 2) {
            //   evt.preventDefault()
            // }
          })

          evt = (evt) ? evt : window.event;
        let charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
            evt.preventDefault();   
        } else if(charCode == 46) {
            evt.preventDefault();   
        } else {
            return true;
        }  
      },

      isNumberCurrentRedirection(evt, indexTable) {
          let totalNumber = 0;
          this.table.currentFundRedirection.forEach((x, index) => {
            totalNumber += +x.percentage
            this.totalCurrentPersenRedirection = totalNumber
            console.log("total number", totalNumber)

            // if (this.table.currentFundRedirection[indexTable].percentage.toString().length > 2) {
            //   evt.preventDefault()
            // }
          })

      },

      checkNumberPurposeRedirection(evt, indexTable) {
          let totalNumber = 0;
          this.table.purposeFundRedirection.forEach((x, index) => {
            totalNumber += +x.percentage
            console.log("total number", totalNumber)

            // if (this.table.purposeFund[indexTable].percentage.toString().length > 2) {
            //   evt.preventDefault()
            // }
          })

          evt = (evt) ? evt : window.event;
        let charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
            evt.preventDefault();   
        } else if(charCode == 46) {
            evt.preventDefault();   
        } else {
            return true;
        }  
      },

      isNumberPurposeRedirection(evt, indexTable) {
          let totalNumber = 0;

          this.table.purposeFundRedirection.forEach((x, index) => {
            totalNumber += +x.percentage
            this.totalPurposePersenRedirection = totalNumber
            console.log("total number", totalNumber)

            // if (this.table.purposeFundRedirection[indexTable].percentage.toString().length > 2) {
            //   evt.preventDefault()
            // }
          })
      },

    changeDropDown(index) {
      console.log('purpose fund', this.table.purposeFund[index].name )
      if(this.table.purposeFund[index].name !== '') {
        let hasDuplicateSwitching: boolean = false;
        let arraySwitching = this.table.purposeFund.filter((x: any) => { if(x.name !== '') return true})
        arraySwitching.map((v: any) => v.name as string).sort().sort((a: any, b: any) => {
          console.log("a", a)
          console.log("b", b)
          if (a === b) hasDuplicateSwitching = true
          return 0;
        }) 
        console.log('hasDuplicate', hasDuplicateSwitching)
        if (hasDuplicateSwitching) {
            this.isLoading = false;
            this.isDisabled = false;
            this.errorMessage = 'Silakan memilih dana investasi yang berbeda'
            this.showModal4()
        }
        this.table.purposeFund[index].isDisabled = false
      } else {
        console.log('purpose fund', this.table.purposeFund[index].name )
        console.log('purpose fund amount', this.table.purposeFund[index].amount )
        this.totalPurposePersenSwitching -=  +this.table.purposeFund[index].percentage
        this.table.purposeFund[index].percentage = ''
        this.table.purposeFund[index].isDisabled = true
      }

    },

    changeDropDownRedirection(index) {
      if(this.table.purposeFundRedirection[index].name !== '') {
        let hasDuplicateRedurection: boolean = false;
        let arrayRedurection = this.table.purposeFundRedirection.filter((x: any) => { if(x.name !== '') return true})
        arrayRedurection.map((v: any) => v.name as string).sort().sort((a: any, b: any) => {
          console.log("a", a)
          console.log("b", b)
          if (a === b) hasDuplicateRedurection = true
          return 0;
        }) 
        console.log('hasDuplicate', hasDuplicateRedurection)
        if (hasDuplicateRedurection) {
            this.isLoading = false;
            this.isDisabled = false;
            this.errorMessage = 'Silakan memilih dana investasi yang berbeda'
            this.showModal4()
        }
        this.table.purposeFundRedirection[index].isDisabled = false
      } else {
        this.totalPurposePersenRedirection -= +this.table.purposeFundRedirection[index].percentage
        this.table.purposeFundRedirection[index].percentage = ''
        this.table.purposeFundRedirection[index].isDisabled = true
      }
    },

    checkPersentase() : number {
          var  count: number = 0
          var a: number = 0
          this.table.currentFundSwitching.forEach((x: any) => {
            //  console.log("xt",x.persentase)
            a += +x.percentage; 
          })
          // console.log("total persent",a)
          return a
      },

      isNumber(evt) {
        // let total = 0;
        // let totalString = "";
        // let totalPercentage = 0;
        // this.table.purposeFund.forEach((x, index) => {
        //   total += Math.ceil(x.amount)
        //   this.saveAmount = total +0
        //   // totalString = this.numberWithCommas(x.amount)
        //   // x.amount = parseInt(totalString);
        //   x.percentage = Math.ceil(x.amount)/ Math.ceil(this.totalCurrentAmount) * 100 
        //   totalPercentage += Math.ceil(x.percentage)
        // })

        // this.totalAmount = this.numberWithCommas( total)
        // this.totalPersen = totalPercentage; 
       
      
      
      },

      createPercentage() {
        // let total = 0;
        // this.table.currentFund.forEach((x, index)=> {
        //   x.percentage = Math.ceil(x.amount)/ Math.ceil(this.totalCurrentAmount) * 100
        //   console.log("total persent", Math.ceil(x.amount)/ Math.ceil(this. totalCurrentAmount) * 100 )
        //   console.log("x percentage", x.percentage)
        //   total += x.percentage
        // })
        // console.log("total percent", total)
        // this.totalCurrentPercentage =  total
 
        
      }

  
    },
  
    mounted() {
      this.policyNumber = this.$store.getters.getPolicyNumber();
      this.policyInsured = this.$store.getters.getPolicyInsured();
      this.policyOwner = this.$store.getters.getPolicyOwner();
      this.product = this.$store.getters.getProduct();
      this.title = this.$store.getters.getTitle();
      this.code1 = this.$store.getters.getCode1();
      this.code2 = this.$store.getters.getCode2();
      this.transactionType = this.$store.getters.getTransactionType();

      // if(this.transactionType === 'Pengalihan Dana Investasi (Fund Switching)') {
      //     this.table.nameHeaderFrom1=  "DARI DANA INVESTASI";
      //     this.table.nameHeaderFrom2= "Total";
      //     this.table.nameHeaderFrom3= "PERSENTASE DANA YANG AKAN DIALIHKAN (%)";
      //     this.table.nameHeaderTo1= "DANA INVESTASI";
      //     this.table.nameHeaderTo2= "Total";
      //     this.table.nameHeaderTo3= "PERSENTASE TUJUAN DANA YANG DIALIHKAN (%)";

      // } else {
      //     this.table.nameHeaderFrom1= "JENIS DANA INVESTASI";
      //     this.table.nameHeaderFrom2= "Total";
      //     this.table.nameHeaderFrom3= "PERSENTASE (%)";
      //     this.table.nameHeaderTo1= "ENIS DANA INVESTASI";
      //     this.table.nameHeaderTo2= "Total";
      //     this.table.nameHeaderTo3= "PERSENTASE (%)";

      // }
      let dataSwitching: any = [];
      let dataRedirection: any = [];
      this.table.currentFundSwitching = [];
      this.table.currentFundRedirection = [];
      if(this.code1 === 'Y') {
        let currentSwitchingFund1 = this.$store.getters.getCurrentFund1() !== undefined ? this.$store.getters.getCurrentFund1() : '-';
        let currentSwitchingFund2 = this.$store.getters.getCurrentFund2() !== undefined ? this.$store.getters.getCurrentFund2() : '-';
        let currentSwitchingFund3 = this.$store.getters.getCurrentFund3() !== undefined ? this.$store.getters.getCurrentFund3() : '-' ;
        let currentSwitchingFund4 = this.$store.getters.getCurrentFund4() !== undefined ? this.$store.getters.getCurrentFund4() : '-' ;
        let currentSwitchingFund5 = this.$store.getters.getCurrentFund5() !== undefined ? this.$store.getters.getCurrentFund5() : '-' ;

        let valueSwitching1 = {
          name: currentSwitchingFund1,
          percentage: ''
        }

        let valueSwitching2 = {
          name: currentSwitchingFund2,
          percentage: ''
        }

        let valueSwitching3 = {
          name: currentSwitchingFund3,
          percentage: ''
        }

        let valueSwitching4 = {
          name: currentSwitchingFund4,
          percentage: ''
        }

        let valueSwitching5 = {
          name: currentSwitchingFund5,
          percentage: ''
        }
        dataSwitching.push(valueSwitching1);
        dataSwitching.push(valueSwitching2);
        dataSwitching.push(valueSwitching3);
        dataSwitching.push(valueSwitching4);
        dataSwitching.push(valueSwitching5);

      }

      if (this.code2 === 'Y') {
        let currentRedirectionFund1 = this.$store.getters.getCurrentRedirectionFund1() !== undefined ? this.$store.getters.getCurrentRedirectionFund1() : '-' ;
        let currentRedirectionFund2 = this.$store.getters.getCurrentRedirectionFund2() !== undefined ? this.$store.getters.getCurrentRedirectionFund2() : '-' ;
        let currentRedirectionFund3 = this.$store.getters.getCurrentRedirectionFund3() !== undefined ? this.$store.getters.getCurrentRedirectionFund3() : '-' ;
        let currentRedirectionFund4 = this.$store.getters.getCurrentRedirectionFund4() !== undefined ? this.$store.getters.getCurrentRedirectionFund4() : '-' ;
        let currentRedirectionFund5 = this.$store.getters.getCurrentRedirectionFund5() !== undefined ? this.$store.getters.getCurrentRedirectionFund5() : '-' ;


        let valueRedirection1 = {
          name: currentRedirectionFund1,
          percentage: ''
        }

        let valueRedirection2 = {
          name: currentRedirectionFund2,
          percentage: ''
        }

        let valueRedirection3 = {
          name: currentRedirectionFund3,
          percentage: ''
        }

        let valueRedirection4 = {
          name: currentRedirectionFund4,
          percentage: ''
        }

        let valueRedirection5 = {
          name: currentRedirectionFund5,
          percentage: ''
        }

        dataRedirection.push(valueRedirection1);
        dataRedirection.push(valueRedirection2);
        dataRedirection.push(valueRedirection3);
        dataRedirection.push(valueRedirection4);
        dataRedirection.push(valueRedirection5);

      }
      
      dataSwitching = dataSwitching.filter((x: any) => {
        if(x.name !== '-') {
          return true;
        } else {
          return false;
        }
      })

      dataRedirection = dataRedirection.filter((x: any) => {
        if(x.name !== '-') {
          return true
        } else {

        }
      })

      console.log("data table Swircing", dataSwitching)
      console.log("data table redurection ", dataRedirection)
      this.table.currentFundSwitching = dataSwitching
      this.table.currentFundRedirection = dataRedirection
    

      // this.table.currentFund[0].name = this.$store.getters.getCurrentFund1();
      // this.table.currentFund[0].amount = this.$store.getters.getCurrentAmount1();
      // this.table.currentFund[1].name = this.$store.getters.getCurrentFund2();
      // this.table.currentFund[1].amount = this.$store.getters.getCurrentAmount2();
      // this.table.currentFund[2].name = this.$store.getters.getCurrentFund3();
      // this.table.currentFund[2].amount = this.$store.getters.getCurrentAmount3();
      // this.table.currentFund[3].name = this.$store.getters.getCurrentFund3();
      // this.table.currentFund[3].amount = this.$store.getters.getCurrentAmount3();
      // this.table.currentFund[4].name = this.$store.getters.getCurrentFund4();
      // this.table.currentFund[4].amount = this.$store.getters.getCurrentAmount4();

      // this.totalCurrentAmount = Math.ceil(this.$store.getters.getCurrentAmount1()) + Math.ceil(this.$store.getters.getCurrentAmount2()) + Math.ceil(this.$store.getters.getCurrentAmount3()) +  Math.ceil(this.$store.getters.getCurrentAmount4()) +  Math.ceil(this.$store.getters.getCurrentAmount5())
  
      // this.createPercentage();

      this.options = [];

      let purposeFundDropDown = JSON.parse(this.$store.getters.getPurposeFund());
      let key = {value: '', text: "Pilih Dana Investasi "} 
      this.options.push(key);
      purposeFundDropDown.forEach(x => {
          let key = {
            value: x,
            text: x
          }
          this.options.push(key)
      })

    },

  
  
  });
