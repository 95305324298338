
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

import { Vue } from "vue-property-decorator";

import otp from "@bachdgvn/vue-otp-input";

/* tslint:disable:no-console */
declare let apigClientFactory: any;

export default Vue.extend({
  data: () => {
    return {
      submitted: false,
      otpm: "",
      isComplite: true,
      title: "",
      errorMessage: "",
      otpResendClickCount: 0,
      resetOtp: 0,
      errorCount: 0,
      showOtpm: true,
      successOtp: false,
      successCheck: true,
      isOtpResendClick: false,
      isLoading: false,
      dismissSecs: 30,
      dismissCountDown: 0,
      errorCountResendOtp: 3,
      isResendOtp: false,
      isErrorOtp: false,
      totalOtp: 0,
      otpSmsButton: false,
      phone: ""
    };
  },
  components: {
    otp
  },
  methods: {
    showModal() {
      this.$root.$emit("bv::show::modal", "modal-3", "#btnShow");
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-3", "#btnShow");
    },
    countDownChanged(dismissCountDown: any) {
      this.dismissCountDown = dismissCountDown;
    },

    handleOnComplete(value: any) {
      // console.log('OTP: ', value);
      if (value.length >= 4) {
        this.otpm = value;
      }
    },

    onBackSpace() {
      console.log("hapus otp");
    },

    handleSubmit() {
      this.submitted = true;
      if (this.otpm.length >= 4) {
        this.isComplite = true;
        this.isLoading = true;
        this.verifyOtp(this.otpm);
      } else {
        this.isComplite = false;
        this.errorMessage = "Anda harus mengisi OTP terlebih dahulu";
        this.showModal();
      }
    },

    verifyOtp(otpInput: string) {
      const auth =
        this.$store.getters.getEncode() !== ""
          ? this.$store.getters.getEncode()
          : null;
      const params = {
        // This is where any header, path, or querystring request params go.
        authorization: auth
      };
      const body = {
        // This is where you define the body of the request
        otp: otpInput,
        requestId: this.$store.getters.getRequestId()
      };
      const additionalParams = {};
      const apigClient = apigClientFactory.newClient();

      apigClient
        .apiV1OtpVerifyPost(params, body, additionalParams)
        .then((response: any) => {
          // this.getStatusOtp();
          this.submitted = false;
          this.isLoading = false;
          this.$store.commit("SET_REQUEST_ID", "");
          this.$router.push("/success");
        })
        .catch((error: any) => {
          this.onBackSpace();
          this.submitted = false;
          this.isLoading = false;
          this.totalOtp += 1;
          this.errorCountResendOtp -= 1;
          this.isErrorOtp = true;

          console.log("count otp", this.totalOtp);

          if (this.totalOtp > 5) {
            this.$router.push("/error");
          } else if (error.data.message === "otp not created") {
            this.$router.push("/error");
          } else if (error.data.message === "time is over") {
            this.$router.push("/info");
          } else if (this.errorCountResendOtp === 0) {
            this.isResendOtp = true;
            this.errorMessage = `Mohon maaf ${this.title} sudah melebihi batas 3 kali input, silahkan merequest ulang otp`;
          } else {
            this.errorMessage = `Kode OTP yang ${this.title} masukan tidak sesuai, ${this.title} memiliki ${this.errorCountResendOtp} kali lagi kesempatan`;
          }
        });
    },

    getStatusOtp() {
      const auth =
        this.$store.getters.getEncode() !== ""
          ? this.$store.getters.getEncode()
          : null;
      const params = {
        // This is where any header, path, or querystring request params go.
        authorization: auth
      };
      const body = {
        // This is where you define the body of the request
        requestId: this.$store.getters.getRequestId()
      };
      const additionalParams = {};
      const apigClient = apigClientFactory.newClient();
      apigClient
        .apiV1OtpStatusPost(params, body, additionalParams)
        .then((response: any) => {
          this.submitted = false;
          this.isLoading = false;
          this.$store.commit("SET_REQUEST_ID", "");
          this.$router.push("/success");
        })
        .catch((error: any) => {
          this.submitted = false;
          this.isLoading = false;
          this.errorMessage = "OTP yang anda masukan salah";
          this.showModal();
        });
    },

    createOtpSms() {
      this.isLoading = true;
      const auth =
        this.$store.getters.getEncode() !== ""
          ? this.$store.getters.getEncode()
          : null;
      const params = {
        // This is where any header, path, or querystring request params go.
        authorization: auth
      };
      const body = {
        // This is where you define the body of the request
        phoneNumber: this.$store.getters.getOwnerHp(),
        type: "VOI"
      };
      const additionalParams = {};
      const apigClient = apigClientFactory.newClient();
      apigClient
        .apiV1OtpCreatePost(params, body, additionalParams)
        .then((response: any) => {
          this.resetOtp += 1;
          this.isResendOtp = false;
          this.isErrorOtp = false;
          this.errorCountResendOtp = 3;
          console.log("reset otp", this.resetOtp);
          this.isLoading = false;
          this.isOtpResendClick = true;
          console.log("otp resend click count", this.otpResendClickCount);
          // this.errorMessage =
          //   "OTP Call sudah dikirim silahkan periksa pangilan anda";
          this.$store.commit("SET_REQUEST_ID", response.data.message.requestId);
          // this.showModal();
        })
        .catch((error: any) => {
          console.log("reset otp", this.resetOtp);
          this.submitted = false;
          this.isLoading = false;
          this.isOtpResendClick = false;
          if (error.data.message == "otp max created") {
            this.resetOtp += 1;
            this.errorMessage =
              "OTP tidak dapat diproses karena melebihi batas maksimum pembuatan OTP";
          } else {
            this.errorMessage = "Gagal memproses OTP , silahkan coba lagi";
          }
          this.showModal();
        });
    },

    setPaddingNum(number: string): string {
      const fullNumber = number;
      const last4Digits = fullNumber.slice(-4);
      const maskedNumber = last4Digits.padStart(fullNumber.length, "X");
      return maskedNumber;
    }
  },
  mounted() {
    if (this.$store.getters.getTitle() === "F") {
      this.title = "Ibu";
    } else if (this.$store.getters.getTitle() === "M") {
      this.title = "Bapak";
    }
    this.title = this.$store.getters.getTitle();
    this.phone = this.setPaddingNum(this.$store.getters.getOwnerHp());
    this.dismissCountDown = this.dismissSecs;
  },

  updated() {
    if (this.$store.getters.getTitle() === "F") {
      this.title = "Ibu";
    } else if (this.$store.getters.getTitle() === "M") {
      this.title = "Bapak";
    }
  }
});
